.separator {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  /* .separator::before, */
  .separator::after 
  {
    content: '';
    flex: 1;
    border-bottom: 1px dashed var(--darkblue);
  }
  
  .separator:not(:empty)::before {
    margin-right: 1em;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1em;
  }

  .property-related-guesthouses {
      padding: 0 10% 2rem 10%;
  }