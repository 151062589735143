.tags-widget {
    padding: 1rem 0;
    width: 100%;
    height: 100%;
   
}
.tag-widget-label {
    padding-left: 0;
}

.tag-cloud {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1rem 0;
    align-items: center;
    
    
}

.popular-tag {
    padding: 0.5rem 1rem;
    background-color: var(--dullblue);
    border: 1px solid var(--mvblue);
    border-radius: 100px;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: 200ms ease-in-out; 
}

.popular-tag.popular-tag:hover {
    
    background-color: var(--neonblue);
    border: 1px solid var(--neonblue);

}