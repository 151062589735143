.info-modal h1 {
  color: var(--neonblue);
  font-weight: 500;
  font-size: 1.5rem;
}

.info-modal-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;

  justify-content: center;
}

.info-modal-item {
  fill: var(--neonblue);
  color: var(--neonblue);
  font-size: 3rem;
  padding: 1rem;
  align-items: center;
}

.info-modal-item h2 {
  color: var(--neonblue);
  font-size: 1.5rem;
  margin: 0;
}
.info-modal-item:nth-child(3) {
  border-top: 2px solid var(--neonblue);
}

.info-modal-list:last-child {
  border-top: 2px solid var(--neonblue);
}
