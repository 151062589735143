.atoll-gallery-wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.atoll-gallery-wrapper div:nth-child(1) {
  grid-column: span 3;
  grid-row: span 3;
}

.atoll-image {
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: 200ms;
}

@media (min-width: 80rem) {
  .atoll-gallery-wrapper {
    align-items: center;
    justify-content: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}
