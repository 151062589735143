.property-header {
  display: grid;
  gap: 5rem;
  row-gap: 1rem;
  grid-template-columns: 100%;
  padding: 6rem 5%;
}

.property-header h1 {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
}

.property-header p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
  margin-top: 5rem;
  color: var(--darkblue);
  margin-bottom: 3rem;
}
.property-header-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 60rem) {
  .property-header {
    display: grid;
    gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: 50% 50%;
    padding: 10rem 10% 5rem 10%;
  }
}

@media (min-width: 80rem) {
  .property-header {
    display: grid;
    gap: 5rem;
    row-gap: 1rem;
    grid-template-columns: 40% 60%;
    padding: 10rem 10% 5rem 10%;
  }

  .property-header h1 {
    color: var(--darkblue);
    font-family: "Britania Ligatura", Sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
    margin: 0;
  }

  .property-header p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
    margin-top: 5rem;
    color: var(--darkblue);
    margin-bottom: 3rem;
  }
  .property-header-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
