.login-page {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  background-size: cover;
  background-repeat: no-repeat;
}

.login-box {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  transition: 500ms ease-in-out;
  align-items: center;
}

.login-box .logo {
  width: 150px;
  margin-bottom: 2rem;
}

.login-label {
  display: block;
  margin: 1rem 0;
}

.login-box .BlueButton {
  margin-top: 2rem;
  width: 100%;
}
.login-box .login-failed{
  text-align: center;
  color: rgb(255, 0, 0);

}

.back-link {
  display: block;
  text-align: right;
  text-decoration: none;
  color: var(--mvblue);
  margin: 2rem 0 0 0;
}

.back-link:hover,
.back-link:focus {
  color: var(--darkblue);
  }
