.popular-activity {
  display: grid;
  grid-template-columns: 100%;
  gap: 3rem;
  margin-top: 4rem;
  max-width: 100vw;
}
.popular-activity-image {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
}
.popular-activity-icon {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  aspect-ratio: 1/1;
  fill: var(--mvblue);
  font-size: 3rem;
  background-color: var(--white);
  padding: 1rem;
  border-radius: 100px;
  border: 3px solid var(--mvblue);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin-top: -2.5rem;
  margin-right: 2.5rem;
}

.popular-activity-name {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  color: var(--white);
  font-size: 0.8rem;
  background-color: var(--mvblue);
  padding: 0.5rem 1rem;
  border-radius: 100px;
  border: 1px solid var(--white);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin-top: -1.25rem;
  margin-right: 0.5rem;
}
.popular-activity-details-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popular-activity-details h2 {
  font-size: 1.5rem;
  margin-top: 0;
}

.popular-activity-details p {
  font-size: 1rem;
}
.popular-activity-details h2:after {
  margin-top: 0.5rem;
  height: 4px;
  display: block;
  width: 100px;
  background: var(--darkblue);
  border-right: 1px white;
  content: "";
}
.islandtag {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}
.islandtag h5 {
  margin: 0;
  display: block;
  font-weight: 400;
  font-size: 1.05rem;
  border-bottom: 1px dashed var(--darkblue);
  margin-bottom: 0.5rem;
  width: 100%;
  cursor: default;
}
.islandtag button {
  background-color: var(--dullblue);
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1px solid var(--lightblue);
  transition: all 500ms;
  cursor: pointer;
  text-transform: capitalize;
}

.islandtag button:hover,
.filter button:active {
  background-color: var(--neonblue);
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1px solid var(--darkblue);
}
/* -------------------------------------------------------------------------- */
/*                                60 REM Styles                               */
/* -------------------------------------------------------------------------- */
@media (min-width: 60rem) {
  .popular-activity {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 5rem;
    margin-top: 4rem;
    max-width: 100vw;
  }
  .popular-activity-image {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 20px;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
  }
  .popular-activity-icon {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;

    aspect-ratio: 1/1;
    fill: var(--mvblue);
    font-size: 3rem;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 100px;
    border: 3px solid var(--mvblue);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin-top: -2.5rem;
    margin-right: 2.5rem;
  }

  .popular-activity-name {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;

    color: var(--white);
    font-size: 1rem;
    background-color: var(--mvblue);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    border: 1px solid var(--white);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin-top: -1.25rem;
    margin-right: 0.5rem;
  }
  .popular-activity-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .popular-activity-details h2 {
    font-size: 2rem;
  }

  .popular-activity-details p {
    font-size: 1rem;
    max-width: 100%;
    margin-right: 10%;
  }
  .popular-activity-details h2:after {
    margin-top: 1rem;
    height: 5px;
    display: block;
    width: 100px;
    background: var(--darkblue);
    border-right: 1px white;
    content: "";
  }
  .islandtag {
    margin: 3rem 0;

    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
  }
  .islandtag h5 {
    margin: 0;
    display: inline-block;
    font-weight: 400;
    font-size: 1.05rem;
    border-bottom: 1px dashed var(--darkblue);
    width: fit-content;
    cursor: default;
  }
  .islandtag button {
    background-color: var(--dullblue);
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid var(--lightblue);
    transition: all 500ms;
    cursor: pointer;
    text-transform: capitalize;
  }

  .islandtag button:hover,
  .filter button:active {
    background-color: var(--neonblue);
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid var(--darkblue);
  }
}
