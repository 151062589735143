.island-facts-info-grid-content-wrapper {
background: none;
padding: 0.5rem 0;
margin: 0;

}

.island-facts-info-grid-content-wrapper:hover {
    background: none;
    padding: 1rem 0;
    margin: 0;
    }
.island-info-widget-wrapper {
   
    width: 100%;
    background: none;
    color: var(--darkblue);
    padding: 1rem 0;

}
.island-info-widget-wrapper:hover {

    background: none;

}


.island-widget-item-row {
    display: flex;
    align-items: center;
    gap: 1em;
    border-top: 1px dashed var(--darkblue);
    padding: 0.5em 1rem;
    width: 100%;
    transition: all 200ms ease-in-out;
    
}

.island-widget-icon-wrapper {
    display: flex;
    width: fit-content;
    padding: 0.5rem;
    background-color: var(--darkblue);
    align-items: center;
    justify-content: center;
    border-radius: 100px;

}

.island-widget-icon {
    fill: var(--white);
    font-size: 1.5em;
    
}

.island-widget-item-row span {
    color: var(--darkblue);
    font-size: 1rem;
    font-weight: 600;

}



.island-info-widget-wrapper div:nth-child(1) {
    border-top: unset;
}

@media (min-width: 60rem) {
    .island-facts-info-grid-content-wrapper {
        background: none;
        padding: 1rem 0;
        margin: 0;
        
        }
        
        .island-facts-info-grid-content-wrapper:hover {
            background: none;
            padding: 1rem 0;
            margin: 0;
            }
        .island-info-widget-wrapper {
            height: fit-content;
            width: 100%;
            background: none;
            color: var(--darkblue);
            padding: 1rem 0;
        
        }
        .island-info-widget-wrapper:hover {
        
            background: none;
        
        }
        
        
        .island-widget-item-row {
            display: flex;
            align-items: center;
            gap: 1em;
            border-top: 1px dashed var(--darkblue);
            border-right: 1px dashed var(--darkblue);
            padding: 0.5em 1rem;
            width: 100%;
            transition: all 200ms ease-in-out;
            
        }
        
        .island-widget-icon-wrapper {
            display: flex;
            width: fit-content;
            padding: 0.5rem;
            background-color: var(--darkblue);
            align-items: center;
            justify-content: center;
            border-radius: 100px;
        
        }
        
        .island-widget-icon {
            fill: var(--white);
            font-size: 1.5em;
            
        }
        
        .island-widget-item-row span {
            color: var(--darkblue);
            font-size: 1rem;
            font-weight: 600;
        
        }
        
        
        
        .island-info-widget-wrapper div:nth-child(1) {
            border-top: unset;
        }
        
}