.footer-container {
  background-color: var(--darkblue);
  margin: 0;
  padding: 10% 2rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-container h1,
.footer-container p {
  margin: 0;
  color: white;
}

@media screen and (min-width: 60rem) {
  .footer-container {
    padding: 5% 2rem;
  }
}

.links ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.links ul li {
  display: inline;
  margin: 0;

  list-style: none;
}

.links a {
  color: var(--neonblue);
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed var(--neonblue);
}
.links a:hover {
  color: var(--dullblue);
  border-bottom: 1px dashed var(--dullblue);
}
