.dashboard-card {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 15px -3px, rgba(0, 0, 0, 0.04) 0px 4px 6px -2px;
    backdrop-filter: blur(7px) ;
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transition: 500ms ease-in-out;
  text-align: center;
  overflow: hidden;
}
.dashboard-card-type {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: var(--blue);
    margin-bottom: 0.5rem;
}

.dashboard-card-title {
    font-size: 1.2rem;
    color: var(--mvblue);
    text-transform:capitalize;
    margin: 0;
}
.dashboard-card-details {
  text-align: left;
  overflow: hidden;
  padding: 1rem 1.5rem;
  width: 50%;
}
.dashboard-card-image {
  width: 30%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
}
.dashboard-card-icon-wrapper {
    padding: 0.5rem;
    width: 2rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    color: var(--mvblue);
}

.dashboard-card-quick-facts {
    display: flex;
    flex-direction: column;
    
}

.dashboard-card-quick-fact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.dashboard-card-controls {
    width: 20%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.dashboard-card-button {
    
    width: 100%;
    height: fit-content;
    background-color: var(--dullblue);
    border-radius: 20px;
    font-weight: 500;
    color: var(--darkblue);
    padding: 0.5rem 1rem;
    outline: unset;
    border: unset;
}

.dashboard-card-button:hover {
    

    background-color: var(--neonblue);
    color: var(--darkblue);

}