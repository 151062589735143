.dashboard-properties-wrapper {
    padding-top: 1rem;
    height: 100%;
   
}
.dashboard-properties {
    height: 75%;
    border-radius: 5px;
   
    overflow-y: auto;
    
}
.dashboard-grid {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-auto-rows: auto;
    gap: 1rem;
  
}

.dashboard-properties-title {
    height: 5%;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: var(--mvblue);
}

.dashboard-properties-subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 5rem;
}