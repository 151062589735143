@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");
nav::before {
  content: '';
  z-index: -2;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}
nav {
  position: fixed;
  width: 100vw;
  height: 5rem;
  margin-bottom: -5rem;
  z-index: 99;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
}
nav .logo {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
}

nav .logo img {
  width: 120px;
  margin-right: 50px;
}
nav .nav-items {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0 0 0 40px;
}
nav .nav-items li {
  list-style: none;
  padding: 0 15px;
}
nav .nav-items li a {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  color: var(--darkblue);
  text-decoration: none;
}
nav .nav-items li a:hover {
  color: var(--blue);
}
nav form {
  display: flex;
  overflow: hidden;
  height: 3rem;
  padding: 2px;
  background: var(--white);
  min-width: 18% !important;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 1px solid rgba(155, 155, 155, 0.2);
}
nav form .search-data {
  width: 100%;
  height: 100%;
  color: var(--darkblue);
  padding: 6px 20px;
  font-family: "Montserrat";
  font-size: 17px;
  border: none;
  font-weight: 500;
  background: none;
  z-index: 1;
}
nav form .search-data:focus {
outline: none;
}
nav form button {
  padding: 0 15px;
  color: var(--darkblue);
  font-size: 17px;
  background: var(--white);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  z-index: 1;
}
nav form button:hover {
  color: var(--mvblue);
}

nav .menu-icon,
nav .cancel-icon,
nav .search-icon {
  width: 40px;
  text-align: center;
  margin: 0 50px;
  font-size: 18px;
  color: var(--darkblue);
  cursor: pointer;
  display: none;
}
nav .menu-icon:hover,
nav .cancel-icon:hover,
nav .search-icon:hover {
  color: var(--mvblue);
}

nav .menu-icon span,
nav .cancel-icon,
nav .search-icon {
  display: none;
}
@media (max-width: 1245px) {
  nav {
    padding: 0 50px;
  }
}
@media (max-width: 1140px) {
  nav {
    padding: 0px;
    overflow-x: clip;
  }
  nav .logo {
    flex: 2;
    text-align: center;
  }
  nav .logo img {
    width: 120px;
    margin-right: 0;
  }
  
  nav .nav-items {
    position: fixed;
    z-index: 99;
    top: 4.95rem;
    width: 100vw;
    right: -100vw;
    /* height: 100vh; */
    padding: 10px 50px 0 50px;
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px)!important;
    -webkit-backdrop-filter: blur(7px)!important;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0%;
  }
  
  nav .nav-items.active {
    right: 0;
    opacity: 100%;
  }
  nav .nav-items li {
    line-height: 40px;
    margin: 30px 0;
  }
  nav .nav-items li a {
    font-size: 20px;
  }
  nav form {
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 80px;
    left: 27px;
    width: 50%;
    opacity: 0;
    pointer-events: none;
    transition: top 0.3s ease, opacity 0.1s ease;
  }
  nav form.active {
    top: 5rem;
    opacity: 1;
    pointer-events: auto;
  }
  nav form:before {
    position: absolute;
    content: "";
    top: -8.5px;
    left: 0px;
    width: 0;
    height: 0;
    z-index: 2;
    border: 10px solid transparent;
    border-bottom-color: var(--white);
    margin: -20px 0 0;
  }
  nav form:after {
    position: absolute;
    content: "";
    height: 60px;
    padding: 2px;
    background: var(--white);
    border-radius: 10px;
    min-width: calc(100% + 20px);
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  nav .search-icon, nav .menu-icon {
    display: block;
  }
  nav .menu-icon span ,
  nav .search-icon span {
    display: block;
  }
  nav .search-icon.hide,nav .menu-icon span.hide
   {
    display: none;
  }

  nav .search-icon span.hide,
  nav .menu-icon.hide {
    display: none;
  }
  nav .cancel-icon.show {
    display: block;
  }
}

@media (max-width: 980px) {
  nav .menu-icon,
  nav .cancel-icon,
  nav .search-icon {
    margin: 0 20px;
  }
  nav form {
    right: 30px;
  }
}
@media (max-width: 350px) {
  nav .menu-icon,
  nav .cancel-icon,
  nav .search-icon {
    margin: 0 10px;
    font-size: 16px;
  }
}