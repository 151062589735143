.island-activities-slider {
   width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
.scroll-indicator {
    fill: var(--mvblue);
    font-size: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
    gap: 1rem;
}

.scroll-indicator span {
    color: var(--darkblue);
    font-size: 1rem;

}

@media (min-width: 60rem) {
    .island-activities-slider {
        width: 90%;
        margin-right: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
    .scroll-indicator {
        fill: var(--mvblue);
        font-size: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 5rem 0;
        gap: 1rem;
    }
    
    .scroll-indicator span {
        color: var(--darkblue);
        font-size: 1rem;
    
    }
}