.atoll-header {
  width: 100vw;
  height: fit-content;
  padding-bottom: 5rem;
}

.atoll-header-image {
  width: 100vw;
  height: 80vh;
  z-index: -2;
  background-color: var(--dullblue);
  background-size: cover;
  background-position: bottom;
  mask-image: url("../../../Assets/Images/mask.png");
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-mode: alpha;
  margin-bottom: -10rem;
}

.atoll-header-content {
  display: grid;
  grid-template-columns: 100%;
  gap: 3rem;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
  padding: 0 5%;
}

.atoll-header-content h1 {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  margin: 0;
  z-index: 1;
}

.atoll-header-grid-col-1,
.atoll-header-grid-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  /* background-color: aquamarine; */
}

.paragraph {
  display: inline;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
  margin-top: 3rem;
  color: var(--darkblue);
  margin-bottom: 1rem;
}

.atoll-attraction-counter-grid {
  display: grid;
  gap: 2rem 0rem;
  justify-content: center;
  align-items: center;
}

.atoll-gallery {
  margin-top: 2rem;
}

.atoll-gallery h3 {
  font-size: 1.5rem;
  color: var(--darkblue);
  text-transform: uppercase;
}

@media (min-width: 40rem) {
  .atoll-header-content {
    max-width: 100vw;
    grid-template-columns: 60% 40%;
    gap: 3rem;
    padding: 2rem 5%;
  }

  .paragraph {
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  .atoll-attraction-counter-grid {
    grid-template-columns: repeat(2, 50%);
    gap: 2.5rem 1rem;
    align-items: flex-start;
  }

  .atoll-gallery {
    margin-top: 4rem;
  }
}

@media (min-width: 80rem) {
  .atoll-header-content {
    max-width: 100vw;
    grid-template-columns: 45% 55%;
    gap: 5rem;
    padding: 2rem 10%;
  }

  .paragraph {
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  .atoll-attraction-counter-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem 1rem;
    align-items: flex-start;
  }

  .atoll-gallery {
    margin-top: 4rem;
  }
}
