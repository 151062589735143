.atoll-single-property {
    display: flex;
    flex-direction: column;
    align-items: left;

}
a:link,
a:visited,
a:hover,
a:active { text-decoration: none; }

.atoll-single-property-icon {
z-index: 2;
display: flex;
align-items: center;
justify-content: center;
width: fit-content;
aspect-ratio: 1/1;
fill: var(--neonblue);
font-size: 1.5rem;
background-color: var(--darkblue);
padding: 1rem;
border-radius: 100px;
margin-bottom: -2rem;
margin-left: 3rem;
border: 2px solid var(--white);
box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.atoll-single-property-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 5/4;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 2rem;
}

.atoll-single-property-name {
    font-weight: normal;
      text-align: left;
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0;
      padding: 0;
}

.atoll-single-property-details {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.atoll-single-property-type {
    font-weight: 400;
    padding-bottom: 1rem;
   width: min-content;
    color: var(--darkblue);
    border-bottom: 1px solid var(--mvblue);
}
.atoll-single-property-features {
    margin: 0;
    padding: 0;
}
.atoll-single-property-features li{
    list-style: none;
    color: var(--mvblue);
}

@media (min-width:40rem) {
    .atoll-single-property {
        display: flex;
        flex-direction: column;
        align-items: left;
    
    }
    a:link { text-decoration: none; }
    
    
    a:visited { text-decoration: none; }
    
    
    a:hover { text-decoration: none; }
    
    
    a:active { text-decoration: none; }
    
    
    .atoll-single-property-icon {
        z-index: 2;
        display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    aspect-ratio: 1/1;
    fill: var(--neonblue);
    font-size: 2rem;
    background-color: var(--darkblue);
    padding: 0.7rem;
    border-radius: 100px;
    margin-bottom: -2rem;
    margin-left: 3rem;
    border: 2px solid var(--white);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
    .atoll-single-property-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 5/4;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 2rem;
    }
    
    .atoll-single-property-name {
        font-weight: normal;
          text-align: left;
          font-weight: 500;
          font-size: 1.15rem;
          margin: 0;
          padding: 0;
    }
    
    .atoll-single-property-details {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
         justify-content: space-between;
    }
    

}

@media (min-width:60rem) {
    .atoll-single-property {
        display: flex;
        flex-direction: column;
        align-items: left;
    
    }
    a:link { text-decoration: none; }
    
    
    a:visited { text-decoration: none; }
    
    
    a:hover { text-decoration: none; }
    
    
    a:active { text-decoration: none; }
    
    
    .atoll-single-property-icon {
        z-index: 2;
        display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    aspect-ratio: 1/1;
    fill: var(--neonblue);
    font-size: 2rem;
    background-color: var(--darkblue);
    padding: 0.7rem;
    border-radius: 100px;
    margin-bottom: -2rem;
    margin-left: 3rem;
    border: 2px solid var(--white);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
    .atoll-single-property-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 5/4;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 2rem;
    }
    
    .atoll-single-property-name {
        font-weight: normal;
          text-align: left;
          font-weight: 500;
          font-size: 1.4rem;
          margin: 0;
          padding: 0;
    }
    
    .atoll-single-property-details {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
    }
    
    .atoll-single-property-type {
        font-weight: 400;
        padding-bottom: 1rem;
        width: 80%;
        color: var(--darkblue);
        border-bottom: 1px solid var(--mvblue);
    }
    .atoll-single-property-features {
        margin: 0;
        padding: 0;
    }
    .atoll-single-property-features li{
        list-style: none;
        color: var(--mvblue);
    }
}