.property-info h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.property-info h4 {
  font-weight: 400;
}

.property-info {
  width: 100%;
  text-transform: uppercase;
  padding: 0 5%;
  align-items: center;
}

.property-info-rating {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  row-gap: 0;
}

.property-info-rating h4 {
  font-size: 1.1rem;
  margin: 0;
  padding-top: 0;
}

.property-info-offers {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 35%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  row-gap: 1rem;
  width: 100%;
  margin: 2rem 0;
}

.property-info-offers h4 {
  font-size: 1.2rem;
}

.property-info-offers-icon-wrapper {
  fill: var(--white);
  background-color: var(--mvblue);
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.property-info-offers span {
  color: var(--white);
  fill: var(--white);
  background-color: var(--mvblue);
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.property-info-offers-description {
  grid-column: span 7;
}

.property-info-offers-description p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: initial;
}

@media (min-width: 80rem) {
  .property-info h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }

  .property-info h4 {
    font-weight: 400;
  }

  .property-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .property-info-rating {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
    row-gap: 0;
  }

  .property-info-rating h4 {
    font-size: 1.1rem;
    margin: 0;
    padding-top: 0;
  }

  .property-info-offers {
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 35%;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    row-gap: 2rem;
    width: 50%;
    margin: 2rem 0;
  }

  .property-info-offers h4 {
    font-size: 1.3rem;
  }

  .property-info-offers-icon-wrapper {
    fill: var(--white);
    background-color: var(--mvblue);
    width: 100%;
    display: flex;
    font-size: 2.3rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .property-info-offers span {
    color: var(--white);
    fill: var(--white);
    background-color: var(--mvblue);
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
  }

  .property-info-offers-description {
    grid-column: span 7;
  }

  .property-info-offers-description p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
