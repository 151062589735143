.featured-island-slide {
  padding: 1rem 1rem 2rem 1rem;
  width: 100%;
  aspect-ratio: 1.91/1;
}

.featured-island-image {
  padding: 0 2rem;
  width: 100%;
  background-color: var(--dullblue);
  background-position: center;
  background-size: cover;
  aspect-ratio: 1/1;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.featured-island-name {
  background: rgba(255, 255, 255, 0.6);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 1);
  border-top: unset;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  max-width: fit-content;
  border-radius: 0 0 10px 10px;
  color: var(--darkblue);
  font-weight: 600;
  font-size: 1rem;
}

/* Slide Widget */
.featured-island-slide .info-widget-wrapper {
  height: fit-content;
  width: fit-content;
  background: rgba(255, 255, 255, 0.4);
  color: var(--darkblue);
  padding: 0.15rem 0;
  position: absolute;
  bottom: 2.5rem;
  right: 1.6rem;
}
.featured-island-slide .info-widget-wrapper:hover {
  background: rgba(255, 255, 255, 0.6);
}
.featured-island-slide .widget-item-row:hover {
  background: rgba(255, 255, 255, 0.1);
}

.featured-island-slide .widget-item-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px dashed var(--darkblue);
  padding: 0.28em 0.5rem;
  width: 100%;
  transition: all 200ms ease-in-out;
}

.featured-island-slide .widget-icon-wrapper {
  display: flex;
  width: fit-content;
  padding: 0.3rem;
  background-color: var(--darkblue);
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.featured-island-slide .widget-icon {
  fill: var(--white);
  font-size: 1.2em;
}

.featured-island-slide .widget-item-row span {
  color: var(--darkblue);
  font-size: 0.8rem;
  font-weight: 600;
}

.featured-island-slide .info-widget-wrapper div:nth-child(1) {
  border-top: unset;
}

.featured-island-slide .WhiteButton {
    position: absolute;
    width: fit-content;
    padding: 5px 10px;
    font-size: 0.7rem;
    bottom:2rem;
    left: 2rem;
}


@media (min-width: 40rem) {
  
  .featured-island-image {
    aspect-ratio: 1.91/1;
  }
  
  .featured-island-name {
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
  }
  
  /* Slide Widget */
  .featured-island-slide .info-widget-wrapper {
    padding: 0.5rem 0;
    position: absolute;
    bottom: 5rem;
    right: 4.5rem;
  }
  
 
  .featured-island-slide .widget-icon-wrapper {
    padding: 0.5rem;
  }
  
  .featured-island-slide .WhiteButton {
      position: absolute;
      padding: 10px 20px;
      font-size: 1.2rem;
      bottom:5rem;
      left: 5rem;
  }
  
}
