.activity-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.activity-modal-section-one {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.modal-featured-image-container {
  width: 100%;
  display: inline;
}
.modal-featured-image {
  width: 100%;
  aspect-ratio: 1 / 0.75;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.modal-description {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;

  flex-wrap: wrap;
}

.modal-description h1 {
  color: var(--white);
  background-color: var(--mvblue);
  padding: 0.5rem 1rem;
  flex-grow: 1;
  border-radius: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.atoll-modal-activity-icon {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  aspect-ratio: 1/1;
  fill: var(--mvblue);
  font-size: 2.5rem;
  background-color: var(--white);
  padding: 0.75rem;
  border-radius: 100px;
  border: 2px solid var(--mvblue);
}

.activity-modal-section-two {
  width: 100%;
  padding: 2rem 0;
  border-top: 1px dashed var(--mvblue);
  border-bottom: 1px dashed var(--mvblue);
}
.modal-island-tag {
  background-color: var(--dullblue);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: inline-block;
  text-transform: capitalize;
  margin: 0.25rem;
  border: 1px dashed var(--mvblue);
  text-decoration: none;
  color: var(--darkblue);
  font-weight: 500;
  transition: all 400ms ease-in-out;
}

.big-tag {
  font-weight: 700;
}
.modal-island-tag:hover {
  background-color: var(--neonblue);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.divtest {
  background-color: red;
}

@media (min-width: 60rem) {
  .activity-modal-section-one {
    flex-direction: row;
  }

  .modal-featured-image-container {
    width: 40%;
  }

  .modal-featured-image {
    aspect-ratio: 1;
    padding-right: 1rem;
  }

  .modal-description {
    width: 60%;
    padding-left: 1rem;
  }
}
