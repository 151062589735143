.form {
  grid-template-columns: 100%;
  

}
.dashboard-properties {
  padding: 1rem;
  border: 1px solid  rgb(228, 228, 238);
  background-color: white;
}
.dashboard-form {
  display: grid;
  grid-template-columns: 50% 50%;
  flex-direction: column;
  gap: 1rem;
  
  
}
.dashboard-button-red {
  background-color: initial;
  background-image: linear-gradient(-180deg, #d70000, #bd0000);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  text-transform: uppercase;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
}

.dashboard-button-red:hover {
  background: #bd0000;
}

.dashboard-button-green {
  background-color: initial;
  background-image: linear-gradient(-180deg, #00D775, #00BD68);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  text-transform: uppercase;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
}

.dashboard-button-green:hover {
  background: #00bd68;
}
.dashboard-form-col-1 {
  display: flex;
  padding: 1rem;
  border: 1px solid #e8ecf1;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 10px;
  gap: 1rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  transition: all 200ms ease-in-out;
 
}

.dashboard-form-col-1:focus-within {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.image-gallery {
  grid-column: 1 / span 2;
}

.image-gallery input[type="file"] {
  display: none;
}

.dashboard-form-col-1 label {
  display: block;
  font-size: 0.9rem;
  color: #32324d;
  text-transform: uppercase;
  font-weight: 500;
  padding: 1rem 0;
}

.dashboard-form-col-1 textarea,
.dashboard-form-col-1 input[type="text"],
.dashboard-form-col-1 select,
.dashboard-form-col-1 option {
  border: 1px solid rgb(220, 220, 228);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  outline: none;
  transition-property: border-color, box-shadow, fill;
  transition-duration: 0.2s;
  box-shadow: unset;
  padding: 12px 20px;
  font-size: 1rem;
}

.half-width {
  width: 50%;
}
.third-width {
  width: 33.33%;
}
.full-width {
  width: 100%;
}
.dashboard-form-col-1 textarea {
  min-height: 150px;
  resize: vertical;
}

.dashboard-form-col-1 textarea:focus-within,
.dashboard-form-col-1 input[type="text"]:focus-within, .dashboard-form-col-1 select:focus {
  border: 1px solid rgb(69, 255, 199);
  box-shadow: rgb(69, 255, 199) 0px 0px 0px 2px;
}

.imgsize {
  font-size: 0.8rem;
  font-weight: 400;
}

.form-img {
  width: 150px;
  aspect-ratio: 1;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#fileuploaded.imagetray,
.imagetray {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  overflow-y: auto;
  background: rgb(246, 246, 249);
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgb(220, 220, 228);
}
.imagetray div {
  color: rgb(199, 199, 199);
}
.image-upload {
  display: inline;
  width: fit-content;
  height: fit-content;
}

.dashboard-form-col-1 [checkbox] {
  display: none;
}

.img-checked-label img {
  border: 5px solid var(--white);
}

.check:checked + .img-checked-label img {
  border: 5px solid red;
  filter: sepia(100%) hue-rotate(300deg) saturate(5);
  cursor: pointer;
}

.img-checked-label img:hover {
  border: 5px solid var(--white);
  cursor: pointer;
}
