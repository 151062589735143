
  .featuredSlider_container__image-container {
    padding: 5px 20px;
    width: 100%;
  }



  .featuredSlider_container__image {
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
     
  }
  
  .gradient {
    position: relative;
    display: inline-block;
    width: 100%;
    
  }
  .gradient:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid var(--white);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: var(--white);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
  
  .featuredSlider_description-wrapper {
    padding: 5px 20px;
  }
  
  .featuredSlider_description-container {
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px;
    padding: 20px;
    align-content: left;
    background: rgba(255, 255, 255, 0.79);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
    backdrop-filter: blur(7.6px);
    -webkit-backdrop-filter: blur(7.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .featuredSlider_description-container h2 {
    display: inline;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--darkblue);
    color: var(--darkblue);
  }
  
  
  
  .featuredSlider_description-container p {
    clear: both;
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
    text-align: left;
  
    color: var(--darkblue);
  }
  
  .featuredSlider_button-wrapper {
    padding: 5px 20px;
    padding-bottom: 30px;
    display: flex;
  }
  
  .featuredSlider_button-container {
    border-radius: 100px;
    padding: 2px 10px;
  
    background: rgba(255, 255, 255, 0.79);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
    backdrop-filter: blur(7.6px);
    -webkit-backdrop-filter: blur(7.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .featuredSlider_button-container h2 {
    
      margin: 0;
    font-size: 1rem;
    line-height: 2rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--blue);
  }
  