.atoll-featured-activities {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 3rem;
    border-bottom: 1px dashed var(--darkblue);
    margin-bottom: 3rem;
}

.section-p {
    max-width: 45rem;
    font-size: 1rem;
    line-height: 1.5rem;

}

.atoll-featured-activity-grid {
    
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 50%);
    grid-auto-rows: 1fr;
    margin-top: 3rem;
    padding: 0;
    width: 90%;
    align-items: center;
    justify-content: center;
}

@media (min-width: 40rem) {

    .atoll-featured-activities {
        padding: 0 20%;
        padding-bottom: 5rem;
        margin-bottom: 5rem;
    }
    
    .section-p {
       line-height: 1.3rem;
    
    }
    
    .atoll-featured-activity-grid {
        gap: 2rem;
        grid-template-columns: repeat(5, 20%);
        grid-auto-rows: 1fr;
        margin-top: 3rem;
        width: 100%;
    }
    
}