.quick-booking {
    border-radius: 2rem 2rem 0 0;
    background: url("https://wordpress-705520-2539783.cloudwaysapps.com/wp-content/uploads/2022/04/Asset-22.png");
    background-position: center;
    background-size: cover;
    padding-top: 2rem;
    margin-top: 2rem;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}