.featured-properties {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 3rem;
}

.section-p {
  max-width: 45rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.featured-property-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: 1fr;
  margin: 2rem 0;
  width: 100%;
  justify-content: center;
}
.property-type-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--darkblue);
  font-size: 1.2rem;
  padding: 0 1rem;
  border-radius: 100px;
  margin: 1rem 0;
}
.property-type-modal-button span > svg {
  fill: var(--neonblue);
  margin: unset;
}

.property-type-modal-button > span {
  padding: 0.5rem 1rem;
  border-left: 2px dashed var(--white);
}

.property-type-modal-button > span:nth-child(1) {
  border-left: unset;
}

.infoModal {
  background-color: #065458;
  width: fit-content;
  border-radius: 20px;
  padding: 2rem;
  color: var(--neonblue);
}

@media (min-width: 40rem) {
  .featured-properties {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 5rem;
  }

  .section-p {
    max-width: 55rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .featured-property-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    margin: 2rem 0;

    width: 90%;
  }
}

@media (min-width: 80rem) {
  .featured-property-grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    margin: 3rem 0;

    width: 90%;
  }
}
