.property-related-slide {
    padding: 1rem;
    cursor: pointer;
    transition: 300ms;

}

.property-related-slide:hover {
    transform: scale(1.1);

}
.property-related-image {
   
    background-size: cover;
    background-position: center;
    aspect-ratio: 4.5/3;
    border-radius: 1rem;
}

.property-related-slide h2 {
    font-size: 1.2rem;
    text-align: left;
    font-weight: 500;
    margin: 1rem 0 0 0;
}

