.atoll-facts {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  border-radius: 2rem;
  background: url(../../../Assets/Images/Asset\ 6.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.atoll-facts h2 {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--white);
  color: var(--white);
  margin-bottom: 1rem;
  margin-top: 0;
}

.tip {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
}

.atoll-detailed-map {
  display: block;
  transition: 1000ms;
  border-bottom: 1px dashed var(--white);
}

.atoll-facts-info-grid {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  padding-top: 1rem;
}
.atoll-facts-info-grid-content-wrapper {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: 1rem;
  transition: 500ms ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.center-to-div-flex {
  align-items: center;
  justify-content: center;
}

.atoll-facts-info-grid-content-wrapper:hover {
  background: rgba(255, 255, 255, 0.2);
}
.mini-map {
  margin: 0;
  max-width: min-content;
  font-size: 70vh;
  fill: rgba(255, 255, 255, 0);
  stroke: var(--white);
  stroke-width: 5px;
  transition: 3000ms;
  width: 100%;
}
.mini-map g {
  transition: 500ms ease-in-out;
  cursor: pointer;
}

.mini-map .active {
  fill: var(--neonblue);
  stroke: var(--white);
  stroke-width: 5px;
}

.mini-map g:hover {
  font-size: 60vh;
  fill: var(--neonblue);
  stroke: var(--white);
  stroke-width: 5px;
}

.atoll-facts-info-grid-column {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
/* .atoll-facts-info-grid-column:last-child{
  display: none;
} */
.atoll-facts-info-grid-column-name-wrapper {
  display: flex;
  flex-direction: row;
}

.atoll-facts-info-grid-column-name {
  display: flex;
  padding: 0.5rem 2rem;
  background-color: var(--dullblue);
  color: var(--darkblue);
  width: max-content;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  margin: 0;
  cursor: pointer;
}

.tip {
  color: var(--white);
}

span.active {
  color: var(--neonblue);
  background-color: var(--darkblue);
  box-shadow: var(--neonblue) 0px 0px 16px;
  transition: all 300ms ease-in-out;
}
.atoll-facts-info-grid-column-name:hover {
  background-color: var(--neonblue);
  color: var(--darkblue);
  box-shadow: var(--neonblue) 0px 0px 16px;
}

.atoll-facts-info-grid-column-span {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.atoll-facts-info-grid-column-label {
  display: inline-block;
  padding: 0 1rem;
  color: var(--white);
  font-size: 0.8rem;
}

.atoll-facts-info-grid-image {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 1rem;
}

.atoll-facts-info-grid h3 {
  color: var(--white);
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  padding-bottom: 0.85rem;
  border-bottom: 1px solid var(--white);
}

.atoll-facts-info-grid p {
  color: var(--white);
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.2rem;
  letter-spacing: 0.05rem;
  text-align: justify;
}

.WhiteButton {
  display: inline;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: left;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  color: var(--mvblue);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  cursor: pointer;
  transition: 500ms;
  text-decoration: none;
  margin: 1rem 0;
}

.WhiteButton a {
  text-decoration: none;
  color: var(--white);
}

.WhiteButton:hover,
.WhiteButton:hover svg {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  color: var(--white);
  fill: var(--white);
}

.WhiteButton:hover {
  background-color: var(--blue);
}

.WhiteButton svg {
  fill: var(--mvblue);
  margin-left: 1rem;
  transition: 500ms;
}

.atoll-info-activity-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  margin: 1rem 0;
}

.atoll-info-activity-icon-box {
  fill: var(--white);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 0.4rem;
  border-radius: 100px;
  transition: 500ms ease-in-out;
  width: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.atoll-info-activity-icon-box:hover {
  fill: var(--darkblue);
  background: var(--neonblue);
}

.atoll-info-activity-icon-box svg {
  font-size: 1.8em;
}

.clearbg,
.clearbg:hover {
  background-color: unset;
  box-shadow: none;
  border: unset;
  padding: unset;
  justify-content: center;
  align-items: center;
}

.widget-label {
  text-align: right;
  width: 100%;
  padding: 1rem 0rem;
  display: none;
}

@media (min-width: 60rem) {
  .atoll-facts {
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: 2rem;
    background: url(../../../Assets/Images/Asset\ 6.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .atoll-facts h2 {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--white);
    color: var(--white);
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .tip {
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
  }

  .atoll-detailed-map {
    display: block;
    transition: 1000ms;
    border-bottom: 1px dashed var(--white);
  }

  .atoll-facts-info-grid {
    width: 100%;
    height: fit-content;
    display: grid;
    gap: 1rem;
    grid-template-columns: 100%;
    padding-top: 1rem;
  }
  .atoll-facts-info-grid-content-wrapper {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1rem;
    border-radius: 1rem;
    transition: 500ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }

  .center-to-div-flex {
    align-items: center;
    justify-content: center;
  }

  .atoll-facts-info-grid-content-wrapper:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .mini-map {
    margin: 0;
    max-width: min-content;
    font-size: 70vh;
    fill: rgba(255, 255, 255, 0);
    stroke: var(--white);
    stroke-width: 5px;
    transition: 3000ms;
    width: 100%;
  }
  .mini-map g {
    transition: 500ms ease-in-out;
    cursor: pointer;
  }

  .mini-map .active {
    fill: var(--neonblue);
    stroke: var(--white);
    stroke-width: 5px;
  }

  .mini-map g:hover {
    font-size: 60vh;
    fill: var(--neonblue);
    stroke: var(--white);
    stroke-width: 5px;
  }

  .atoll-facts-info-grid-column {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .atoll-facts-info-grid-column-name-wrapper {
    display: flex;
    flex-direction: row;
  }

  .atoll-facts-info-grid-column-name {
    display: flex;
    padding: 0.5rem 2rem;
    background-color: var(--dullblue);
    color: var(--darkblue);
    width: max-content;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0;
    cursor: pointer;
  }

  .tip {
    color: var(--white);
  }

  span.active {
    color: var(--neonblue);
    background-color: var(--darkblue);
    box-shadow: var(--neonblue) 0px 0px 16px;
    transition: all 300ms ease-in-out;
  }
  .atoll-facts-info-grid-column-name:hover {
    background-color: var(--neonblue);
    color: var(--darkblue);
    box-shadow: var(--neonblue) 0px 0px 16px;
  }

  .atoll-facts-info-grid-column-span {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }

  .atoll-facts-info-grid-column-label {
    display: inline-block;
    padding: 0 1rem;
    color: var(--white);
    font-size: 0.8rem;
  }

  .atoll-facts-info-grid-image {
    width: 100%;
    border: 1px solid var(--white);
    border-radius: 1rem;
  }

  .atoll-facts-info-grid h3 {
    color: var(--white);
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    padding-bottom: 0.85rem;
    border-bottom: 1px solid var(--white);
  }

  .atoll-facts-info-grid p {
    color: var(--white);
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.2rem;
    letter-spacing: 0.05rem;
    text-align: justify;
  }

  .WhiteButton {
    display: inline;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: left;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--mvblue);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    transition: 500ms;
    text-decoration: none;
    margin: 1rem 0;
  }

  .WhiteButton a {
    text-decoration: none;
    color: var(--white);
  }

  .WhiteButton:hover,
  .WhiteButton:hover svg {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    color: var(--white);
    fill: var(--white);
  }

  .WhiteButton:hover {
    background-color: var(--blue);
  }

  .WhiteButton svg {
    fill: var(--mvblue);
    margin-left: 1rem;
    transition: 500ms;
  }

  .atoll-info-activity-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 1fr;
    gap: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    max-width: 100%;
    margin: 1rem 0;
  }

  .atoll-info-activity-icon-box {
    fill: var(--white);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 1);
    padding: 0.4rem;
    border-radius: 100px;
    transition: 500ms ease-in-out;
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
  }

  .atoll-info-activity-icon-box:hover {
    fill: var(--darkblue);
    background: var(--neonblue);
  }

  .atoll-info-activity-icon-box svg {
    font-size: 1.8em;
  }

  .clearbg,
  .clearbg:hover {
    background-color: unset;
    box-shadow: none;
    border: unset;
    padding: unset;
    justify-content: center;
    align-items: center;
  }

  .widget-label {
    text-align: right;
    width: 100%;
    padding: 1rem 0rem;
    display: none;
  }
}

@media (min-width: 80rem) {
  .atoll-facts {
    background-color: red;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem;
    border-radius: 2rem;
    background: url(../../../Assets/Images/Asset\ 6.jpg);
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .atoll-facts h2 {
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--white);
    color: var(--white);
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .tip {
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
  }

  .atoll-detailed-map {
    display: block;
    transition: 1000ms;
    border-bottom: 1px dashed var(--white);
  }

  .atoll-facts-info-grid {
    max-width: 100%;
    height: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: 50% 50%;
    padding-top: 1rem;
  }
  .atoll-facts-info-grid-content-wrapper {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1rem;
    border-radius: 1rem;
    transition: 500ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }

  .center-to-div-flex {
    align-items: center;
    justify-content: center;
  }

  .atoll-facts-info-grid-content-wrapper:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .mini-map {
    margin: 0;
    font-size: 70rem;
    fill: rgba(255, 255, 255, 0);
    stroke: var(--white);
    stroke-width: 5px;
    transition: 3000ms;
    width: 100%;
  }
  .mini-map g {
    transition: 500ms ease-in-out;
    cursor: pointer;
  }

  .mini-map .active {
    fill: var(--neonblue);
    stroke: var(--white);
    stroke-width: 5px;
  }

  .mini-map g:hover {
    font-size: 60vh;
    fill: var(--neonblue);
    stroke: var(--white);
    stroke-width: 5px;
  }

  .atoll-facts-info-grid-column {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .atoll-facts-info-grid-column:last-child {
    display: block;
  }
  .span3 {
    grid-column: span 3;
  }
  .atoll-facts-info-grid-column-name-wrapper {
    display: flex;
    flex-direction: row;
  }

  .atoll-facts-info-grid-column-name {
    display: flex;
    padding: 0.5rem 2rem;
    background-color: var(--dullblue);
    color: var(--darkblue);
    width: max-content;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0;
    cursor: pointer;
  }

  .tip {
    color: var(--white);
  }

  span.active {
    color: var(--neonblue);
    background-color: var(--darkblue);
    box-shadow: var(--neonblue) 0px 0px 16px;
    transition: all 300ms ease-in-out;
  }
  .atoll-facts-info-grid-column-name:hover {
    background-color: var(--neonblue);
    color: var(--darkblue);
    box-shadow: var(--neonblue) 0px 0px 16px;
  }

  .atoll-facts-info-grid-column-span {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
  }

  .atoll-facts-info-grid-column-label {
    display: inline-block;
    padding: 0 1rem;
    color: var(--white);
    font-size: 0.8rem;
  }

  .atoll-facts-info-grid-image {
    width: 100%;
    border: 1px solid var(--white);
    border-radius: 1rem;
  }

  .atoll-facts-info-grid h3 {
    color: var(--white);
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    padding-bottom: 0.85rem;
    border-bottom: 1px solid var(--white);
  }

  .atoll-facts-info-grid p {
    color: var(--white);
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.2rem;
    letter-spacing: 0.05rem;
    text-align: justify;
  }

  .WhiteButton {
    display: inline;
    height: fit-content;
    width: fit-content;
    align-items: center;
    justify-content: left;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--mvblue);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    transition: 500ms;
    text-decoration: none;
    margin: 1rem 0;
  }

  .WhiteButton a {
    text-decoration: none;
    color: var(--white);
  }

  .WhiteButton:hover,
  .WhiteButton:hover svg {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    color: var(--white);
    fill: var(--white);
  }

  .WhiteButton:hover {
    background-color: var(--blue);
  }

  .WhiteButton svg {
    fill: var(--mvblue);
    margin-left: 1rem;
    transition: 500ms;
  }

  .atoll-info-activity-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 1fr;
    gap: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    max-width: 100%;
    margin: 1rem 0;
  }

  .atoll-info-activity-icon-box {
    fill: var(--white);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 1);
    padding: 0.4rem;
    border-radius: 100px;
    transition: 500ms ease-in-out;
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;
  }

  .atoll-info-activity-icon-box:hover {
    fill: var(--darkblue);
    background: var(--neonblue);
  }

  .atoll-info-activity-icon-box svg {
    font-size: 1.8em;
  }

  .clearbg,
  .clearbg:hover {
    background-color: unset;
    box-shadow: none;
    border: unset;
    padding: unset;
  }

  .widget-label {
    text-align: right;
    width: 100%;
    padding: 1rem 0rem;
  }
}
