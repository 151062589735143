.FamousFor_Container {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    
}

.FamousFor_Container > span {
    display: flex;
    background-color: var(--dullblue) ;
    padding: 0.8rem 1.2rem;
    
    border-radius: 1rem;
    border:   1px dashed var(--darkblue);
}

