.reviews {
  background-color: var(--mvblue);
  padding: 1rem;
  margin: unset;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: 100%;
  text-transform: initial;
}

.reviews h3 {
  color: var(--white);
  margin-top: 0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px dashed var(--white);
  width: fit-content;
  font-size: 1rem;
}
.reviews p {
  color: var(--white);
  margin: 0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px dashed var(--white);
  font-size: 0.8rem;
}

.reviews h5 {
  color: var(--white);
  margin-bottom: 0;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
}

@media (min-width: 60rem) {
  .reviews {
    background-color: var(--mvblue);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: 6fr 1fr;
  }

  .reviews h3 {
    color: var(--white);
    margin-top: 0;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px dashed var(--white);
    width: fit-content;
  }
  .reviews p {
    color: var(--white);
    margin: 0;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px dashed var(--white);
  }

  .reviews h5 {
    color: var(--white);
    margin-bottom: 0;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
}
