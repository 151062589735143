.island-header {
  display: grid;
  gap: 2rem;
  grid-template-columns: 100%;
  padding: 10rem 5%;
  padding-bottom: 2rem;
}

.island-header h1 {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  margin: 0;
}
.island-tutorial-image img {
  width: 100%;
  margin: 2rem 0;
}
.island-header p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
  margin-top: 3rem;
  color: var(--darkblue);
  margin-bottom: 3rem;
}
.island-header p:nth-child(3) {
  margin-top: 0rem;
}

@media (min-width: 80rem) {
  .island-header {
    display: grid;
    gap: 5rem;
    grid-template-columns: 1fr 1fr;
    padding: 10rem 10% 5rem 10%;
  }

  .island-header h1 {
    color: var(--darkblue);
    font-family: "Britania Ligatura", Sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
    margin: 0;
  }

  .island-header p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    text-align: justify;
    margin-top: 5rem;
    color: var(--darkblue);
    margin-bottom: 3rem;
  }
  .island-header p:nth-child(3) {
    margin-top: 0rem;
  }
}
