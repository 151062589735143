.atoll-featured-islands {
  padding: 0;
}

.section-h1 {
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
}
@media (min-width: 40rem) {
  .atoll-featured-islands {
    padding: 0 10%;
  }
  
  .section-h1 {
    text-align: center;
    font-weight: 300;
    font-size: 2.5rem;
  }
}