.destinations-hero {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 3rem 10%;
  background-position: center;
  background-attachment: scroll;
}

.hero-title {
  font-size: 3rem;
  color: var(--white);
  font-weight: 500;
}

@media (min-width: 40rem) {
  .destinations-hero {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 5rem 10%;
    background-position: center;
    background-attachment: fixed;
  }

  .hero-title {
    font-size: 4rem;
    color: var(--white);
    font-weight: 500;
  }
}
