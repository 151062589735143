.island-guesthouses-wrapper {
    display: grid;
    gap: 5rem;
    grid-template-columns: 1fr 1fr;
    padding: 0 10% 2rem 10%;
  
  
  }

  .island-guesthouses-title {
      font-weight: 300;
      font-size: 2.5rem;
  
      
  }

  .island-guesthouses-grid {

    grid-column: span 2;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        margin: 3rem 0;
    
        width: 100%;
    }
  
    .infobox-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

  
    .centerbluebutton {
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .centerbluebutton .BlueButton {
 margin: 0;
    }