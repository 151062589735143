.tagLine {
  grid-area: one;
  margin-top: 10rem;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  
}

.tagLine h1 {
  color: var(--white);
  font-family: "Britania Ligatura", Sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  margin: 0;
  
}

.tagLine p {
  color: var(--white);
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
}

@media (min-width: 50em) {

  .tagLine {
      padding: 0 15%;
          margin-top: 5rem;
  }

  .tagLine h1 {

      font-size: 2.6rem;

    }
}
@media (min-width: 80em) {

    .tagLine {
        padding: 0 20%;
           
    }

    .tagLine h1 {

        font-size: 3.5rem;
 
      }
  }