.packages-hero {
  min-height: 100vh;
  background-image: url("../../Assets/Images/packagesHero.jpg");
  background-position: bottom center;
  background-size: cover;
  background-attachment: fixed;
  padding: 5rem 5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.packages-hero h1 {
  color: var(--white);
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 10rem;
}

/* -------------------------------------------------------------------------- */
/*                                 40 rem size                                */
/* -------------------------------------------------------------------------- */

@media (min-width: 40rem) {
  .packages-hero {
    background-position: bottom center;
    background-size: cover;
    background-attachment: fixed;
    padding: 10rem 10%;
  }

  .packages-hero h1 {
    color: var(--white);
    font-size: 4rem;
    font-weight: 400;
  }
}
