.Activity_Icon_Wrapper {
  
    display: grid;
      gap: 0.5rem;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(7, 1fr);
      padding-left: 0;
      
}


.Activity_Icon {
    font-size: 3em;
    fill: var(--darkblue);
    
}



.Activity_Icon_Wrapper h4 {
    color: var(--darkblue);
    font-weight: 600;
    text-align: center;

}




.Activity_Icon_Box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--neonblue);
    padding: 1em;
    border-radius: 100px;
    border: 3px solid var(--white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      aspect-ratio: 1/1;
    transition: 200ms;
    
}

.Activity_Icon_Box:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    
}