.booking-page {
  min-height: 100vh;
  min-width: 100vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.readonly {
  color: white;
}
.readonly textarea,
.readonly textarea:focus-within {
  outline: unset;
  background-color: transparent;
  color: white;
  box-shadow: unset;
  font-weight: 400;
  word-wrap: normal;
  height: fit-content;
  resize: none;
}

.booking-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgba(19, 24, 19, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  transition: 500ms ease-in-out;
  align-items: center;
  max-width: 40rem;
}
@media (min-width: 40rem) {
  .booking-box {
    padding: 3rem;
  }
  .booking-form {
    display: grid;
    justify-content: center;
    width: 100%;
    column-gap: 2rem;
    grid-template-columns: 50% 50%;
    align-items: center;
  }

  .booking-form div:nth-child(14),
  .booking-form div:nth-child(13) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .booking-form div:nth-child(1),
  .booking-form div:nth-child(2),
  .booking-form div:nth-child(7),
  .booking-form div:nth-child(8),
  .booking-form div:nth-child(14),
  .booking-form div:nth-child(13) {
    grid-column: 1 / span 2;
  }
}
.note * {
  color: white;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.3rem;
  font-size: 0.8rem;
}

.note h4 {
  width: 100%;
  display: block;
  text-align: left;
}

.note li {
  margin-bottom: 0.5rem;
}
.booking-box .logo {
  width: 150px;
  margin-bottom: 2rem;
}
.booking-box h1 {
  text-align: center;
  color: var(--white);
  font-size: 2rem;
  font-weight: 400;
}
.booking-label {
  color: var(--white);
  font-weight: 400;
  display: block;
  margin: 1rem 0;
}
.required {
  color: red;
  font-size: 1.2rem;
  margin-left: 10px;
}

.submit-instruction {
  color: var(--white);
  font-size: 0.8rem;
}

.booking-box .BlueButton {
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  width: max-content;
  border: 1px solid white;
}

.back-link {
  display: block;
  width: 100%;
  text-align: right;
  text-decoration: none;
  color: var(--white);
  margin: 2rem 0 0 0;
}

.back-link:hover,
.back-link:focus {
  color: var(--neonblue);
}
.booking-form .failed-submission p {
  text-align: center;
  color: rgb(255, 0, 0);

}