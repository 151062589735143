.MapDetailsGallery {
display: contents;


}

.MapDetailsGallery_wrapper {
  align-items: center;
  justify-content: left;
    width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.MapDetailsGallery_wrapper img {
  padding: 0;

}



.MapDetailsGallery_wrapper div:nth-child(1) {
  grid-column: span 2;
  grid-row: span 2;
}

.GalleryItem {
   
  width: 100%;
 aspect-ratio: 1/1;
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
cursor: pointer;
 border-radius: 1rem;
 border: 3px solid var(--white);
 box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

 transition: all 200ms;
}

.GalleryItem:hover {
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}