.destinations-content-wrapper {
  display: grid;
  grid-template-columns: 100%;
  padding: 0 10%;
}

.map-image {
  width: 0;
}

.map-image-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
}

@media (min-width: 80rem) {
  .destinations-content-wrapper {
    grid-template-columns: 65% 35%;
    padding: 0 5%;
  }
  .map-image {
    padding-top: 5rem;
    width: 100%;
  }
}
