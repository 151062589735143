.dashboard {
  display: grid;
  height: 97%;
  width: 98%;
  grid-template-columns: 11rem auto;
  color: aliceblue;
}
.dashboard-content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.chart {
  width: 40%;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.dashboard-figure {
  padding: 1rem;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: aquamarine;
}

.dashboard-figure-title {
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
}

.dashboard-figure-amount {
  display: block;
  font-size: 2.8rem;
  font-weight: 600;
}

.row {
  flex-direction: column;
}
