.atoll-single-activity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.atoll-single-activity-icon {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  aspect-ratio: 1/1;
  fill: var(--white);
  font-size: 2.5rem;
  background-color: var(--mvblue);
  padding: 1rem;
  border-radius: 100px;
  margin-bottom: -2rem;
  border: 2px solid var(--white);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.atoll-single-activity-image {
  width: 100%;
  aspect-ratio: 4/5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2rem;
}

.atoll-single-activity-name {
  font-weight: normal;
  text-align: center;
}

.customOverlay {
  background: rgba(0, 0, 0, 0.5);
}
.customModal {
  background: #ffffff;
  max-width: unset;
  width: 95vw;
  margin: unset;
  border-radius: 20px;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.2);
}

.react-responsive-modal-closeButton {
  font-size: 1.5rem;
  color: var(--mvblue);
}
.react-responsive-modal-closeButton:focus,
.react-responsive-modal-closeButton:hover {
  outline: unset;
  color: red;
}

@media (min-width: 60rem) {
  .customModal {
    width: 60vw;
    padding: 2rem;
  }
}
