.destinations-grid {
  padding: 2rem 0;
  gap: 2rem 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}

.bold {
  font-weight: bold;
}

.destinations-island {
  list-style: none;
  line-height: 1.8rem;
}

.destinations-island a {
  color: var(--darkblue);
}

.destinations-atollname {
  color: var(--mvblue);
  font-weight: 400;
  line-height: 1rem;
}

.destinations-atollcode {
  text-transform: uppercase;
  color: var(--dullblue);
  line-height: 0;
  font-weight: 400;
}

.destinations-list {
  padding: 10rem 0 0 0;
}

.section-header {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
}

h2.section-header {
  font-family: Montserrat;
  color: var(--darkblue);
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding-top: 5rem;
}

.destinations-list p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

@media (min-width: 40rem) {
  .destinations-grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (min-width: 60rem) {
  .destinations-grid {
    grid-template-columns: repeat(3, 33.33%);
  }
}

@media (min-width: 80rem) {
  .destinations-grid {
    grid-template-columns: repeat(5, 20%);
    border-right: 1px dashed var(--darkblue);
  }
  h2.section-header {
    font-family: Montserrat;
    color: var(--darkblue);
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    padding-top: 5rem;
    border-right: 1px dashed var(--darkblue);
    padding-right: 5rem;
  }

  .destinations-list p {
    font-size: 1rem;
    line-height: 1.5rem;
    border-right: 1px dashed var(--darkblue);
    padding-right: 5rem;
    margin-bottom: 0;
  }
}
