.dashboard {
    background-image: url(../../../Assets/Images/login.jpg);
    background-size: cover;
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 15rem auto;
    color: var(--mvblue);
    overflow: hidden;
    padding: 1rem;
    gap: 1rem;
    
  }

.dashboard-content-area {
    height: 100%;
    overflow: hidden;
    padding: 2rem;
    padding-top: 0;
    background-color: var(--white);
    border-radius: 10px;
    position: relative;

}
.dashboard-top-bar {
    padding: 1rem 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

.dashboard-top-bar a {
    color: var(--mvblue);
}

.logout-button {
    padding: 0.5rem;
}
/* Dashboar styles */

.dashboard-sidebar{
    display:flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position:relative;
    padding-top: 2rem;
    background-color: rgba(0, 24, 23, 0.2);
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    background-size: cover;
    text-transform: uppercase;
  }

  .dashboard-sidebar img {
    width: 90%;
    height: auto;
    padding: 0 0;
  }
.dashboard-menu{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    
    flex-direction: column;
  }

  .dashboard-menu-item a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size:1rem;
    color: var(--white);
    margin: 1rem 0;
    font-size:1rem;
    color: var(--white);
  }
.dashboard-menu-item a:hover:not(.active) {
  color: var(--neonblue);
}

.dashboard-menu-item .active {
 
  color: var(--neonblue);
 
}
  .dashboard-menu-item:last-child a{
    border-bottom: unset;
  }

  .dashboard-menu-item:first-child .active  {
    
   
    color: var(--neonblue);
    width: 100%;
    border-radius: 200px;
    margin-bottom: 5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .dashboard-menu-item:first-child .menu-icon-wrapper{

    font-size: 1.5rem;
  }

  .menu-icon-wrapper {
    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.3rem;
  }

  .dashboard-heading {
    color:  #178892;
    font-size: 1rem;
    margin-left: 1.5rem;
    font-weight: 400;
    cursor: default;
    user-select: none;
  }
/* NEW STYLES */
.menu-wrapper {
    height: 100vh;
    overflow: hidden;
    background-image: url(../../../Assets/Images/login.jpg);
    background-size: cover;
    background-position: center;
}
.sideBar {
    position: relative;
    z-index: 20;
    height: 100vh;
    width: 15%;
    transition: 0.3s ease-in-out;
    align-items: center;
    padding: 2rem 2rem;
}



.sideBar.widthChange {
    width: 5%;
    align-items: center;
    text-align: center;
    padding: 2rem 0.2rem;
}


.logout-button {
    color: white;
}


label.hideMenuList {
    display: none;
}


.sideBar .cross-icon {
    display: none;
    color: #001629;
}

.sidebar-header {
    display: flex;
}

.content {
    width: 100%;
    height: 100vh;
    
}

header {
    padding-top: 2rem;
    background-color: rgba(0, 24, 23, 0.5);
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    height: 3rem;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

header h1 {
    color: var(--neonblue);
    font-size: 1.3rem;
    font-weight: 400;
}

#mobile {
    display: none;
}

.menu-button {
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
   
    color: white;
    font-size: 1.5rem;
}

.menu-button div:nth-child(1) {
    position: absolute;
    background-color: var(--blue);
    height: 4px;
    border-radius: 20px;
    width: 100%;
}

.menu-button div:nth-child(2) {
    position: absolute;
    background-color: var(--dullblue);
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    border-radius: 20px;
    width: 80%;
}

.menu-button div:nth-child(3) {
    position: absolute;
    background-color: var(--blue);
    height: 4px;
    border-radius: 20px;
    bottom: 0;
    width: 100%;
}

header img {
    height: 40px;
    aspect-ratio: 1;
    border-radius: 100%;
}


.content-data {
    background-color: #ffffff;
    margin: 2%;
    padding: 20px;
    height: calc(100% - 7rem);
    border-radius: 20px;
    overflow-y: auto;
}

.sideBar.showMenu {
    left: 0;
}




@media(max-width:1200px) {
    .sideBar {
        width: 30%;
    }
}

@media(max-width:900px) {
    #desktop {
        display: none;
    }

    #mobile {
        display: block;
    }

    .sideBar {
        position: absolute;
        width: 50%;
        top: 0;
        left: -100%;
    }

    .sideBar .cross-icon {
        display: block;
        align-self: flex-end;
        margin-top: -1rem;
        margin-right: -1rem;
        color: white;
    }

    .backdrop {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        left: -100%;
        height: 100vh;
        width: 100%;
    }

    .backdrop.showBackdrop {
        left: 0;
    }
}

@media(max-width:700px) {
    .sideBar {
        width: 60%;
    }
}

@media(max-width:400px) {
    .sideBar {
        width: 80%;
    }

    header h1 {
        font-size: 20px;
    }

    #mobile {
        height: 25px;
    }
}

@media(max-width:320px) {
    .sideBar {
        width: 80%;
    }
}