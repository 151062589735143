.featured-island-slider {
    margin-top: 2rem;
    margin-bottom: 3rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  @media (min-width: 60rem) {
    .featured-island-slider {
      margin-top: 2rem;
      margin-bottom: 5rem;
      padding: 0 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
  }
