.Map_Details_container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

}

.Map_Details_container h2 {
    display: inline;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--darkblue);
    color: var(--darkblue);
    width: fit-content;
    text-transform: uppercase;
    padding-top: 2rem;
}



.Map_Details_container img {
    max-width:100%;
max-height:100%;
padding: 2rem;
}

.Map_Details_container h3 {
    display: inline;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--darkblue);
   color: var(--darkblue);
   margin-top: 3rem;
   margin-bottom: 2rem;
    
}

.Map_Details_container p {
    display: inline;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: justify;
    padding-bottom: 2rem;
    border-bottom: 1px dashed var(--darkblue);
   color: var(--darkblue);
     margin-bottom: 2rem;
    
}


.BlueButton {
    display: inline;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: left;
    padding: 1rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
    text-transform: uppercase;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mvblue);
    border: 3px solid var(--white);
    color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    transition: 500ms;
    text-decoration: none;
    
    
}

.BlueButton a {
    text-decoration: none;
    color: var(--white);
}

.BlueButton:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: var(--blue);
    
}

.BlueButton svg {
fill: var(--white);
margin-left: 1rem;
}

.BlueButtonDisabled {
    background-color: gray;
  
}