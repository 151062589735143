@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: 'Britania Ligatura';
  src: local('Britania Ligatura'), url(./fonts/Britania_Ligatura.ttf)format('truetype');
}
:root {
  --blue: #017D98;
  --mvblue: #0070b8;
  --darkblue: #06363A;
  --neonblue: #0BFCB1;
  --dullblue: #bce2ec;
  --white: #ffffff;
  --lightblue: #9ED7E8;
 
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Montserrat', sans-serif;
  
}

body {
  margin: 0;
  background-color: var(--white);
  overflow-x: hidden;
  
}

main {
  margin-top: 7rem;
  text-align: center;
}

h1,
h2,
h3,
p {
  color: var(--darkblue);
  
}

p {
  line-height: 1.5rem;
}
.britania {
  font-family: "Britania Ligatura", Sans-serif;
}

/* width */
::-webkit-scrollbar {
  background: var(--white);
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mvblue);
  border-radius: 10px;
}

input[type=text],input[type=date], input[type=number], input[type=email],input[type=password], textarea {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  border-radius: 10px;
  color: var(--darkblue);
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 
}

input[type=text]:focus,input[type=date]:focus, input[type=number]:focus, input[type=email]:focus,input[type=password]:focus, textarea:focus {
outline: 2px solid var(--neonblue);
}
