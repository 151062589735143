.tutorial_Grid_Container {
  overflow-x: hidden;
  width: 100%;
  background-color: var(--white);
  display: grid;
  gap: 0.5rem;
  grid-auto-columns: 100%;
  grid-template-areas: "one" "two";
}

.tutorial_Header_Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 5%;
}

.tutorial_Header_Wrapper h1 {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  margin: 0;
}

.tutorial_Icon_Wrapper {
  align-items: center;
  justify-content: center;
  padding: 0 5% 2.5rem 5%;
  display: grid;
  gap: 0.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one two three four five six";
}
.tutorial_Icon {
  font-size: 2.4em;
  fill: var(--darkblue);
}

.tutorial_Icon_Wrapper h3 {
  color: var(--darkblue);
  font-size: 0.8rem;
  font-weight: 600;
}

.tutorial_Icon_Box {
  background-color: var(--dullblue);
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  border-radius: 0.8rem;
  border: 1px solid #9ed7e8;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (min-width: 40rem) {
  .tutorial_Grid_Container {
    gap: 1.5rem;
  }

  .tutorial_Header_Wrapper h1 {
    font-size: 2rem;
  }

  .tutorial_Icon {
    font-size: 3em;
  }

  .tutorial_Icon_Wrapper h3 {
    font-size: 1rem;
  }
}

@media (min-width: 50rem) {
  .tutorial_Grid_Container {
    grid-auto-columns: 30% 70%;
    grid-template-areas: "one two";
  }
  .tutorial_Header_Wrapper {
    padding: 2.5rem 20%;
  }
  .tutorial_Header_Wrapper h1 {
    font-size: 2rem;
  }
  .tutorial_Icon_Wrapper {
    align-items: center;
    justify-content: center;
    padding: 5rem 15%;
  }

  .tutorial_Icon {
    font-size: 2.8em;
  }

  .tutorial_Icon_Wrapper h3 {
    font-size: 1rem;
  }
}

@media (min-width: 80rem) {
  .tutorial_Grid_Container {
    grid-auto-columns: 50%;
    grid-template-areas: "one two";
  }
  .tutorial_Header_Wrapper {
    justify-content: left;
    padding: 5rem 20%;
  }

  .tutorial_Header_Wrapper h1 {
    font-size: 2.5rem;
  }

  .tutorial_Icon_Wrapper {
    justify-content: left;
    padding: 5rem 20%;
    padding-left: 0;
  }
  .tutorial_Icon {
    font-size: 5em;
  }

  .tutorial_Icon_Wrapper h3 {
    font-size: 1.2rem;
  }

  .tutorial_Icon_Box {
    padding: 1em;
    border-radius: 0.8rem;
  }
}
