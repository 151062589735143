.booking-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  border-radius: 10px;

}

.booking-detail-cell {
  padding: 0 1rem;
  min-width: 150px;
  justify-content: center;
  align-items: center;
  border-left: 1px dashed var(--dullblue);
  
}
.span {
    flex-grow: 1;
}
.booking-detail-cell:last-child { 
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
}

.booking-detail-cell:first-child {
    border-left: unset;
}

.booking-detail-label {
  margin: 0.7rem 0;
  font-size: 0.7rem;
  
  color: var(--blue);
  font-weight: 400;
}

.booking-detail-value {
  color: var(--mvblue);
  font-size: 0.9rem;
  font-weight: 500;
}


.booking-detail-status {
    
margin-right: 0.5rem;


  }

  .capitalize {
    text-transform: capitalize;
  }
  
  .status-completed {
    color: green;
}
  
.status-active {
    color: blue;
}

.status-cancelled {
    color: red;
}

.status-pending {
    color: yellow;
}

.booking-detail-button {
    
   
    background-color: var(--white);
    border-radius: 20px;
    font-weight: 500;
    color: var(--blue);
    padding: 0.5rem 1rem;
}

.booking-detail-button:hover {
    

    color: var(--darkblue);
    
   

}