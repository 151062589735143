.activity-grid-container {
  margin: 5rem 5%;
}

.activity-grid {
  margin-top: 3rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 40%);
  gap: 2rem;
}

.activity-grid-container h1 {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 300;
}

.activity-grid-container p {
  max-width: 100%;
}

@media (min-width: 40rem) {
  .activity-grid-container {
    margin: 5rem 10%;
  }

  .activity-grid {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
  }

  .activity-grid-container h1 {
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 300;
  }

  .activity-grid-container p {
    max-width: 50%;
  }
}
