.fof-container {
min-height: 100vh;
min-width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: var(--blue);
}

.fof-container h1,.fof-container  h3,.fof-container  a {
    color: var(--blue);
}