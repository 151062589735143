.hero {
  background-color: var(--mvblue);
  min-height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 100%;
  grid-template-areas: "one" "two";
  transition: background-image 1s ease-in-out;
}
.featuredSlider_container {
  grid-area: two;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  padding: 0 5%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.featuredSlider_container h1 {
  color: var(--white);
  font-family: "Britania Ligatura", Sans-serif;
  font-weight: normal;
  font-size: 5rem;
  margin: 0;
}

.featuredSlider_container p {
  color: var(--white);
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
}

@media (min-width: 40rem) {
  .hero {
    grid-template-columns: 40% 60%;
    grid-template-areas: "one two";
  }
  .featuredSlider_container {
    display: flex;
    margin-top: 10rem;
    margin-bottom: 5rem;
    padding: 0 15%;
  }
}

@media (min-width: 80rem) {
  .hero {
    grid-template-columns: 50% 50%;
    grid-template-areas: "one two";
  }
  .featuredSlider_container {
    display: flex;
    margin-top: 10rem;
    margin-bottom: 5rem;
    padding: 0 20%;
  }
}
