.search-result-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 0 5rem 0;
  font-weight: 400;
  margin: 0;
}

.result-image {
  width: 100%;
  aspect-ratio: 1/0.91;
  background-size: cover;
  background-position: center;
}

.search-result {
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
}
.search-results {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  gap: 1rem;
  padding: 0 10%;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  font-weight: 400;
  list-style: none;
}
.pagination li {
  color: rgb(11, 20, 24);
  margin: 0 1px;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a {
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #17beca;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
