.mini-select {
  overflow-x: hidden;
  width: 100%;
  background-color: var(--white);
  padding: 2rem 05px;
  justify-content: center;
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0px;
}
.mini-select-grid-1,
.mini-select-grid-2,
.mini-select-grid-3 {
  display: flex;
}

.mini-select-grid-3 a {
  background-color: var(--neonblue);
  color: var(--darkblue);
  fill: var(--darkblue);
}

.mini-select-grid-1 {
  grid-area: 1 / 1 / 2 / 2;
  justify-content: flex-end;
  flex-direction: column;
}
.mini-select-grid-2 {
  grid-area: 1 / 2 / 2 / 3;
  align-items: flex-start;
}
.mini-select-grid-3 {
  grid-area: 2 / 1 / 3 / 3;
  justify-content: center;
}

.mainMap_Grid_Container {
  overflow-x: hidden;
  width: 100%;
  background-color: var(--white);
  display: grid;
  padding: 2rem 0;
  grid-auto-columns: 100%;
  grid-template-areas: "one" " two";
  justify-content: center;
  align-items: stretch;
}

.mainMap_Map_Wrapper {
  display: flex;
  height: 90vh;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.mainMap_Details_Wrapper {
  min-height: 100vh;
  display: flex;
  padding: 0 5%;
  padding-top: 2.5rem;
  background-color: white;
}

#Labels path {
  fill: #06363a;
  cursor: auto;
}

svg#map {
  fill: var(--mvblue);
  stroke: white;
  font-size: 100vh;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

svg#map .hiddenhover {
  fill: rgba(41, 255, 97, 0);
  stroke: rgba(41, 255, 97, 0);
  stroke-width: 0px;
  filter: y=1000% x= 1000% drop-shadow(0px 0px 40px rgb(0, 255, 200));
  cursor: pointer;
}
svg#map g > path {
  pointer-events: bounding-box;
  cursor: pointer;
  transition: 1000ms;
}

svg#map g:hover > path {
  fill: #06363a;
  stroke: #ffffff;
}

.active-atoll {
  fill: var(--darkblue);
}

@media (min-width: 50rem) {
  .mini-select {
    width: 50%;
    padding-left: 10%;
    display: none;
    grid-column-gap: 1.5rem;
  }

  .mini-select-grid-3 {
    justify-content: flex-end;
  }

  .mainMap_Grid_Container {
    grid-auto-columns: 45% 55%;
    grid-auto-rows: 1fr;
    grid-template-areas: "one two";
    margin: 0 0 5rem 0;
    padding: 0 10%;
    padding-bottom: 2rem;
  }

  .mainMap_Map_Wrapper {
    height: 100vh;
    padding-right: 10%;
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
  }

  .mainMap_Details_Wrapper {
    min-height: unset;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    padding-left: 5rem;
    border-left: 2px dashed var(--mvblue);
  }
}
@media (min-width: 100rem) {
  .mainMap_Grid_Container {
    grid-auto-columns: 50%;
  }
  .mainMap_Map_Wrapper {
    height: 200vh;

    align-self: flex-start;
    justify-content: flex-start;
  }
}
