.island-info {
    display: grid;
    grid-template-columns:100%;
    grid-template-rows: auto;
   
    
}

.island-info h3 {
    font-size: 1.2rem;
    color: var(--darkblue);

}

.island-tags {
    padding: 1rem;
  
}

.island-stats {
  
    padding: 1rem 1rem 0 1rem;
    margin: 0;
}
.island-activities {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.island-popular-tags {
    display: flex;
    flex-wrap: wrap;
    gap:  0.5rem;
}
.island-activities span {
    background-color: var(--dullblue);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 1rem;
}

.island-popular-tags span {
    background-color: var(--mvblue);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 1rem;
    color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 1px solid var(--white);
    transition: 200ms;
    cursor: pointer;
}

.island-popular-tags span:hover {
    background-color: var(--blue);
    
}

@media (min-width: 60rem) {
    .island-info {
        display: grid;
        grid-template-columns: 3fr 5fr;
       
        
    }
    
    .island-info h3 {
        font-size: 1.2rem;
        color: var(--darkblue);
    
    }
    
    .island-tags {
        padding: 1rem;
      
    }
    
    .island-stats {
      
        padding: 1rem;
    }
    .island-activities {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    .island-popular-tags {
        display: flex;
        flex-wrap: wrap;
        gap:  0.5rem;
    }
    .island-activities span {
        background-color: var(--dullblue);
        padding: 0.5rem 1rem;
        border-radius: 100px;
        font-size: 1rem;
    }
    
    .island-popular-tags span {
        background-color: var(--mvblue);
        padding: 0.5rem 1rem;
        border-radius: 100px;
        font-size: 1rem;
        color: var(--white);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        border: 1px solid var(--white);
        transition: 200ms;
        cursor: pointer;
    }
    
    .island-popular-tags span:hover {
        background-color: var(--blue);
        
    }
    
}