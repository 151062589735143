.island-activities-wrapper {
    display: grid;
    gap: 5rem;
    grid-template-columns:100%;
    padding: 0 5% 0 5%;
  
  }

  .island-activities-title {
      font-weight: 300;
      font-size: 2rem;
  
      
  }
  

  @media (min-width: 60rem) {
    .island-activities-wrapper {
        display: grid;
        gap: 5rem;
        grid-template-columns: 1fr 1fr;
        padding: 0 10% 2rem 10%;
      
      }
    
      .island-activities-title {
          font-weight: 300;
          font-size: 2.5rem;
      
          
      }
      
      .island-activities-slider {
          grid-column: span 2;
    
      }
  }