.filter {
  margin: 3rem 0;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.filter h5 {
  margin: 0;
  display: inline-block;

  width: fit-content;
}

.filter button {
  background-color: var(--dullblue);
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1.5px dashed var(--darkblue);
  transition: all 500ms;
  cursor: pointer;
}

.filter button:hover,
.filter button:active {
  background-color: var(--mvblue);
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border: 1.5px solid var(--darkblue);
}

@media (min-width: 60rem) {
  .filter {
    margin: 3rem 0;
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
  }

  .filter h5 {
    margin: 0;
    display: inline-block;

    width: fit-content;
  }

  .filter button {
    background-color: var(--dullblue);
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1.5px dashed var(--darkblue);
    transition: all 500ms;
    cursor: pointer;
  }

  .filter button:hover,
  .filter button:active {
    background-color: var(--mvblue);
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1.5px solid var(--darkblue);
  }
}
