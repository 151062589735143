.package-wrapper {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 3rem;
}

div.package-wrapper {
  align-items: flex-start;
}
.package-gallery-wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-bottom: 1.5rem;
}
.package-details-column {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--darkblue);
}
.package-gallery-wrapper div:nth-child(1) {
  grid-column: span 3;
  grid-row: span 3;
}

.package-icon-set {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.package-type {
  color: var(--darkblue);
  font-size: 1.8rem;
}

.package-single-activity {
  display: flex;
  fill: white;
  background-color: var(--mvblue);
  padding: 0.5rem;
  font-size: 1.5rem;
  border-radius: 50px;
  width: 3rem;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

h1.package-name {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

p.package-location {
  text-transform: uppercase;
  background-color: var(--dullblue);
  display: flex;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  margin: unset;
  border: 1px dashed var(--mvblue);
}

p.package-description {
  display: block;
  min-width: 100%;
}
.package-image {
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: 200ms;
}

.feature-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.feature-wrapper .feature-icon-wrapper {
  background-color: var(--mvblue);
  padding: 0.5rem;
  border-radius: 10px;
  font-size: 1.3rem;
  width: 2rem;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.feature-icon-wrapper {
  fill: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.single-feature-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@media (min-width: 80rem) {
  .package-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 3rem;
  }
  .package-gallery-wrapper {
    align-items: center;
    justify-content: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .package-details-column {
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
    padding-left: 3rem;
    border-top: unset;
    border-left: 2px dashed var(--darkblue);
  }
}
