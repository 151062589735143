.island-activities-slide {
    width: 100vw;
    padding:0 10px;
    display: grid;
    grid-template-columns: 90%;
    gap: 0.5rem;

  }
  
  .island-activities-image {
  
  
    width: 100%;
    background-color: var(--dullblue);
  
    background-position: center;
    background-size: cover;
  
    aspect-ratio: 1.5/1;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;

  }

  .island-activities-description {
      background-color: var(--mvblue);
      border-radius: 1rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 1rem 2rem;
  }

  .island-activities-description h3 {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--white);
    color: var(--white);
    
  }

  .island-activities-description p {
      font-size: 0.8rem;
      color: var(--white);
      text-align: left;
  }

  .island-activities-gallery {
     
      display: grid;
      grid-template-columns: repeat(6, 14%);
      gap: 0.5rem;
  }

  .island-activities-gallery-image {
    width: 100%;
    background-color: var(--dullblue);
  
    background-position: center;
    background-size: cover;
  margin-bottom: 2rem;
    aspect-ratio: 1;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .island-activities-slide-col-2 {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      gap: 1rem;
    
  }

  
@media (min-width: 80rem) {
  .island-activities-slide {
    
    width: 100%;
    padding: 20px 10px;
    display: grid;
    grid-template-columns: 2fr 6fr;
    gap: 1rem;

  }
  
  .island-activities-image {
  
  
    width: 100%;
    background-color: var(--dullblue);
  
    background-position: center;
    background-size: cover;
  
    aspect-ratio: 1;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;

  }

  .island-activities-description {
      background-color: var(--mvblue);
      border-radius: 1rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 1rem 2rem;
  }

  .island-activities-description h3 {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px dashed var(--white);
    color: var(--white);
    
  }

  .island-activities-description p {
      font-size: 0.8rem;
      color: var(--white);
      text-align: left;
  }

  .island-activities-gallery {
     
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      gap: 0.5rem;
  }

  .island-activities-gallery-image {
    width: 100%;
    background-color: var(--dullblue);
  
    background-position: center;
    background-size: cover;
  
    aspect-ratio: 1;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }

  .island-activities-slide-col-2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 1fr;
      gap: 1rem;
    
  }
}