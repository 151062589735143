.q-booking-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 5%;
}

.form-label {
  color: var(--darkblue);
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.booking-buttons-container {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.booking-buttons-container > span {
  display: flex;
  background-color: var(--dullblue);
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  border: 1px solid var(--blue);
  font-size: 1.2rem;
}

.booking-buttons-container > span:hover {
  background-color: var(--neonblue);
  border: 1px solid var(--darkblue);
  cursor: pointer;
}

.booking-buttons-container > span:active {
  background-color: var(--neonblue);
  border: 1px solid var(--darkblue);
  cursor: pointer;
}
.booking-form > div {
  width: 100%;
}
.half-width {
  max-width: calc(100% - 1rem);
}

.full-width {
  max-width: calc(100% - 1rem);
}

.grow {
  flex-grow: 1;
}

@media (min-width: 60rem) {
  .q-booking-form {
    width: 50%;
  }
  .grow {
    flex-grow: 0;
  }
  .half-width {
    max-width: 40%;
  }
  .full-width {
    max-width: calc(100% - 1rem);
  }
}

.q-booking-form textarea {
  display: flex;
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 10rem;
}

.form-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px dashed var(--white);
  padding: 2rem 0;
}

.form-submit p {
  width: 100%;
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif;
}

.field-wrap {
  position: relative;
  width: 100%;
}

.field-icon {
  position: absolute;
  left: 15px;
  top: 11px;
  color: var(--blue);
}

.field {
  padding: 10px !important;
  padding-left: 40px !important;
  font-family: "Montserrat", sans-serif;
  min-width: 100% !important;
}

.checked-label {
  display: flex;
  background-color: var(--dullblue);
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  border: 1px solid var(--blue);
  font-size: 1.2rem;
}

.check:checked + .checked-label {
  background-color: var(--darkblue);
  border: 1px solid var(--neonblue);
  color: var(--neonblue);
  cursor: pointer;
}

.checked-label:hover {
  background-color: var(--neonblue);
  border: 1px solid var(--darkblue);
  cursor: pointer;
}
.check {
  display: none;
}
