.property-booking {
  background-image: url("../../../Assets/Images/formbg.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  overflow: hidden;
  padding: 5rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.property-booking h2 {
  color: var(--white);
  font-size: 2rem;
  border: 2px dashed var(--white);
  width: max-content;
  padding: 1rem 2rem;
  border-radius: 20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 2rem;
}

.property-booking h3 {
  color: var(--white);
  display: block;
  clear: both;

  text-decoration: underline;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
}

.property-booking-form-container {
  background-color: var(--mvblue);
  width: 100%;
  border-radius: 20px;
  padding: 1rem 0;
  border: 2px solid var(--white);
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
}
.property-booking-form-container h4 {
  color: var(--white);
  width: fit-content;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--white);
}

.property-booking-form-container p {
  color: var(--white);
}

.property-booking-form-container div {
  padding: 0 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.property-booking-form-container div:last-of-type {
  border-right: unset;
}

.confirm-your-trip {
  background-color: var(--lightblue);
  width: 100%;
  border-radius: 20px;
  padding: 2rem 2rem;
  border: 3px solid var(--white);
  margin: 1rem 0 3rem 0;
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
}

.confirm-your-trip-field {
  background: var(--white);
  border-radius: 20px;
  padding: 1rem 2rem;
}

.confirm-your-trip-field:nth-child(5) {
  order: -1;
}

.confirm-your-trip-field:nth-child(1) {
  order: -2;
}

.confirm-your-trip p {
  margin: 0;
  padding: 10px 0 10px 30px;
  list-style: none;
  background-image: url("../../../Assets/Images/checkmark.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}

@media (min-width: 60rem) {
  .property-booking {
    background-image: url("../../../Assets/Images/formbg.jpg");
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    padding: 5rem 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .property-booking h2 {
    color: var(--white);
    font-size: 2rem;
    border: 2px dashed var(--white);
    width: max-content;
    padding: 1rem 2rem;
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 5rem;
  }

  .property-booking h3 {
    color: var(--white);
    display: block;
    clear: both;

    text-decoration: underline;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .property-booking-form-container {
    background-color: var(--mvblue);
    width: max-content;
    border-radius: 20px;
    padding: 1rem 2rem;
    border: 3px solid var(--white);
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .property-booking-form-container h4 {
    color: var(--white);
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--white);
    text-align: center;
  }

  .property-booking-form-container p {
    color: var(--white);
    text-align: center;
  }

  .property-booking-form-container div {
    border-right: 1px dashed var(--white);
    padding: 0 1rem 0 1rem;
  }

  .property-booking-form-container div:last-of-type {
    border-right: unset;
  }

  .confirm-your-trip {
    background-color: var(--lightblue);
    width: 100%;
    border-radius: 20px;
    padding: 2rem 2rem;
    border: 3px solid var(--white);
    margin: 1rem 0 3rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-template-rows: repeat(2, 1fr);
  }

  .confirm-your-trip-field {
    background: var(--white);
    border-radius: 20px;
    padding: 1rem 2rem;
  }

  .confirm-your-trip-field:nth-of-type(3) {
    grid-row: span 2;
  }

  .confirm-your-trip-field:nth-of-type(5) {
    grid-column: span 2;
  }
  .confirm-your-trip-field:nth-child(5) {
    order: 0;
  }

  .confirm-your-trip p {
    margin: 0;
    padding: 10px 0 10px 30px;
    list-style: none;
    background-image: url("../../../Assets/Images/checkmark.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
  }
}
