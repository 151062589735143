.info-widget-wrapper {
    height: fit-content;
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    color: var(--darkblue);
    padding: 1rem 0;
}
.info-widget-wrapper:hover {

    background: rgba(255, 255, 255, 0.4);

}
.widget-item-row:hover {
    
    background: rgba(255, 255, 255, 0.2);
   
}

.widget-item-row {
    display: flex;
    align-items: center;
    gap: 1em;
    border-top: 1px dashed var(--darkblue);
    padding: 0.5em 1rem;
    width: 100%;
    transition: all 200ms ease-in-out;
}

.widget-icon-wrapper {
    display: flex;
    width: fit-content;
    padding: 0.5rem;
    background-color: var(--darkblue);
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.widget-icon {
    fill: var(--white);
    font-size: 1.5em;
    
}

.widget-item-row span {
    color: var(--darkblue);
    font-size: 1rem;
    font-weight: 600;
}

.info-widget-wrapper div:nth-child(1) {
    border-top: unset;
}
