.quick-booking-wrapper {
  display: grid;
  grid-template-columns: 100%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.booking-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 0 5%;
}

.booking-header-wrapper h1 {
  color: var(--darkblue);
  font-family: "Britania Ligatura", Sans-serif;
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
}

.booking-header-wrapper p {
  color: var(--darkblue);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: justify;
}

.tutorial-icon-wrapper {
  display: grid;
  align-items: flex-start;
  justify-content: start;
  grid-template-columns: 4fr 1fr 4fr 1fr 4fr 1fr 4fr;
  padding: 0 5%;
}

.tutorial-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  fill: var(--darkblue);
}

.tutorial-mid {
  display: flex;
  height: 100%;
  padding-top: 3rem;
  color: var(--darkblue);
  font-size: 0.9rem;
}

.tutorial-number {
  font-size: 2rem;
  padding-bottom: 0.8rem;
}
.tutorial-icon {
  font-size: 2.5em;
  fill: var(--darkblue);
}

.tutorial-icon-wrapper h4 {
  color: var(--darkblue);
  font-weight: 600;
  text-align: center;
  font-size: 0.8rem;
}

.tutorial-icon-wrapper span {
  align-items: center;
  justify-content: center;
  color: var(--darkblue);
  font-weight: 900;
  text-align: center;
  font-size: 1.8em;
}

.tutorial-icon-box {
  display: flex;
  background-color: var(--dullblue);
  padding: 0.7em;
  border-radius: 0.8em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: fit-content;
  aspect-ratio: 1/1;
  transition: 200ms;
}

/* .Activity_Icon_Box:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
} */

@media (min-width: 50rem) {
  .quick-booking-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
  
  .booking-header-wrapper {
    padding: 0 10%;
    padding-right: 0;
  }
  
  .booking-header-wrapper h1 {
    font-size: 2.2rem;
    margin: 0;
    text-align: left;
  }
  
  .booking-header-wrapper p {
    padding-right: 2em;
    border-right: 2px dashed var(--white);
    margin-bottom: 2rem;
  }
  
  .tutorial-icon-wrapper {
    padding: 0 10%;
    padding-left: 0;
  }

  
  .tutorial-mid {
    padding-top: 3.5rem;
  }
  
  .tutorial-number {
    font-size: 2.5rem;
   
  }
  .tutorial-icon {
    font-size: 3em;
  }
  
  .tutorial-icon-wrapper h4 {
    font-size: 1rem;
  }
  
  .tutorial-icon-wrapper span {
    font-size: 2.2em;
  }
  
  .tutorial-icon-box {
    padding: 0.9em;
    border-radius: 0.9em;
  }
}

@media (min-width: 80rem) {
  .quick-booking-wrapper {
    gap: 5rem;
  }
  
  .booking-header-wrapper {
    padding: 0 20%;
    padding-right: 0;
  }
  
  .booking-header-wrapper h1 {
    font-size: 2.5rem;
  }
  
  .booking-header-wrapper p {
    padding-right: 3em;
  }
  
  .tutorial-icon-wrapper {
    padding: 0 20%;
    padding-left: 0;
  }
  
  .tutorial-mid {
    padding-top: 4rem;
  }
  
  .tutorial-number {
    font-size: 2.8rem;
  }
  .tutorial-icon {
    font-size: 4em;
  }
  
  .tutorial-icon-wrapper h4 {
     font-size: 1.2rem;
  }
  
  .tutorial-icon-wrapper span {
    font-size: 2.8em;
  }
  
  .tutorial-icon-box {
    padding: 1em;
    border-radius: 1em;
  }
}