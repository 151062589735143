.property-activities-select {
  margin: 3rem 5%;
}

.property-activities-select h3 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.property-activities-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 40%);
  gap: 1rem;
}
.property-activities-grid-item-image {
  background-size: cover;
  background-position: center;
  aspect-ratio: 0.8/1;
  border-radius: 3rem;
  position: relative;
}

.property-activities-grid h4 {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1rem 0;
  padding: 0;
  color: var(--darkblue);
}

.round {
  position: relative;
}

.round label {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 1rem;
  position: absolute;
  top: 1rem;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--neonblue);
  border-color: #fff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
  border-color: var(--darkblue);
}

@media (min-width: 60rem) {
  .property-activities-select {
    margin: 5rem 10% 3rem;
  }

  .property-activities-select h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .property-activities-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    margin: 3rem;
  }
  .property-activities-grid-item-image {
    background-size: cover;
    background-position: center;
    aspect-ratio: 0.8/1;
    border-radius: 3rem;
    position: relative;
  }

  .property-activities-grid h4 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 1rem 0;
    padding: 0;
    color: var(--darkblue);
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 1rem;
    position: absolute;
    top: 1rem;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: var(--neonblue);
    border-color: #fff;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: var(--darkblue);
  }
}
